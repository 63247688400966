<template>
  <div>
    <center>
        <br><br><br><br><br>
        <img src="elementos/404.png" class="rounded" alt="Image" >
        <br>
        <v-btn color="#5cb617" class="ml-3" dark style="text-transform:capitalize;" @click="$router.push('/')">
          Regresar al Home
        </v-btn>
    </center>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>