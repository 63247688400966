<template>
  <v-app :id="ranking ? 'fondo2' : 'fondo'" >
    <header v-if="!ranking"> <img :src="isMobile ? 'elementos/header_movil.png' : 'elementos/header.png'"
     class="rounded float-left" alt="Image" :style="!isMobile ? 'margin-top:-65px; z-index: -1;':'width:95%;'">
    </header>
    <v-main :style="isMobile ? 'z-index: 24; margin-top:40px;' : 'z-index: 24; margin-top:-140px;'">       
        <router-view />
    </v-main>
    <footer v-if="!ranking">
      <img src="elementos/footer.png" class="rounded float-right" alt="Image" style="margin-top:28px; z-index: -1; " >
    </footer>
  </v-app>
</template>

<script>
// import $ from "jquery";
export default {
  name: 'App',
  data(){
    return{
      isMobile: this.$mobile(),
      ranking:false
    }
  },
  created() {
    this.$bus.$on("cambia_fondo",
      () => {
        this.ranking = true;
      });

    this.$bus.$on("cambia_fondo2",
      () => {
        this.ranking = false;
      });
  }
};
</script>
<style>
 #fondo{
     background-image: url('../public/elementos/fondo.jpg');
     width: 100%!important;
    }

  #fondo2{
     background-image: url('../public/elementos/pista.jpg');
     
     width: 100%!important;
    }
  /* #footer {
    width: 100%;
    height: 81px;
    position: absolute;
    bottom: 0;
    left: 0;
} */

</style>